@font-face {
  font-family: 'Terminal Grotesque Open';
  src: url('terminal-grotesque_open.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Terminal Grotesque';
  src: url('terminal-grotesque.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zpix';
  src: url('zpix.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Junicode';
  src: url('Junicode.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Junicode';
  src: url('Junicode-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Junicode';
  src: url('Junicode-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Junicode';
  src: url('Junicode-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic, oblique;
}
