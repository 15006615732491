.packages {
  display: flex;
  width: 60vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warning {
  display: none;
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url('assets/0113.jpg');
  background-blend-mode: multiply; */
  background-color: rgb(17, 42, 56);
  padding-bottom: 200px;
}

.p1 {
  width: 60vw;
  position: relative;
  background-color: rgb(17, 42, 56);
}
.p1-text {
  color: rgb(195, 214, 86);
  margin: 10vh;
  text-align: left;
  font-family: monospace;
  font-weight: 500;
  line-height: 200%;
  position: relative;
}

#deyu-id {
  display: block;
}

#deyu-id-mob {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .packages {
    width: 90vw;
  }
  .p1 {
    width: 90vw;
    position: relative;
  }

  .p1-text {
    margin: 0;
  }

  #short-chats {
    width: 100vw;
  }
}

.p1 {
  width: 100%;
}

.p1-puzzle {
  position: relative;
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
}

.p1 img {
  width: 20vw;
  max-width: 250px;
  position: absolute;
}

html {
  background-color: rgb(17, 42, 56);
}

.p1-link {
  color: rgb(21, 230, 230);
  text-decoration: underline;
  cursor: pointer;
}

.p1-name {
  color: rgb(255, 162, 115);
}

.tbc {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: center;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 10vh 0 10vh 0;
  color: rgb(33, 131, 144);
  font-size: 24px;
  font-family: 'Terminal Grotesque Open';
}

#frog-div {
  position: absolute;
  top: 900px;
  left: 200px;

  img#frog {
    width: 500;
  }

  img#frogdew {
    width: 200;
  }
}

.header-container {
  width: 100vw;
}

#header-mobile {
  display: none;
}

.credit {
  text-align: end;

  font-family: 'Junicode';
  color: #ffdedf;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  a,
  a:visited {
    text-decoration: wavy;
    text-decoration-color: #d1311c;
    text-decoration-line: underline;
    color: rgb(105, 202, 178);
    cursor: pointer;
  }
}

.chinese {
  font-family: 'zPix';
}
.bios {
  max-width: 40vw;
}
.shared-bio {
  color: #d6ca21;
  margin: 20px;
  font-family: 'Terminal Grotesque';
}

.i-bio {
  color: #62d3d9;
  margin: 20px;
  font-family: 'Terminal Grotesque';
}

.s-bio {
  color: #a2a0ef;
  margin: 20px;
  font-family: 'Terminal Grotesque';
}

.about-header {
  font-size: 32px;
  font-family: 'Terminal Grotesque Open';
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #d6ca21;
  margin: 40px;
  img {
    width: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .warning {
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: center;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 10vh 0 10vh 0;
    color: rgb(228, 84, 59);
    font-size: 14px;
    font-family: 'Terminal Grotesque';
  }

  .warning img {
    width: min-content;
  }

  .warning-header {
    font-size: 32px;
    font-family: 'Terminal Grotesque Open';
  }

  .p1 img {
    width: 150px;
    position: absolute;
  }

  #deyu-id-mob {
    display: block;
  }

  #deyu-id {
    display: none;
  }

  #frog-div {
    position: absolute;
    top: 55vh;
    left: 10%;

    img#frog {
      width: 50vw;
    }

    img#frogdew {
      width: 20vw;
    }
  }

  #header-mobile {
    display: block;
  }

  #header {
    display: none;
  }

  .credit {
    position: relative;
    bottom: 0;
    right: 0;
    font-size: smaller;
  }

  .bios {
    max-width: none;
  }
}
